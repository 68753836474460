import authAxios from "../services/authAxios";
import UnauthAxios from "../services/unauthAxios";
import ROOT_URL from "./api-url";
/* if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1'){
    console.log(1);
    ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/v'
}else{
    // TODO: Server API URL
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
    console.log(2);
} */
const apiTerms = {
  getDashboard_Earning: (postData) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/app/dashboard/earnings?month=${postData.month}`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),

  getNotification: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/dashboard/notifications`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),
  getNotificationPagin: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/notification/pagin`,
      data:data
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),
  post_Orders: (postData) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/app/dashboard`,
      data: postData,
    }).catch((error) => {
      console.log(error);
      return Promise.reject(error);
    }),

  //used in the dashboard to show todays earning
  getTodaysEarning: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/dashboard/todaysEarning`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),

  // reports page api start
  get_todaysEarning: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/reports/todaysEarnings?month=${id}`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),
  get_orderReport: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/reports/order?month=${id}`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),
  get_averageRating: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/reports/ratings`,
    }).catch((err) => {
      console.log(err);
      return Promise.reject(err);
    }),
  // reports page api end
};

export default apiTerms;
