import React, { Component } from 'react'

export  default class ErrorPage extends Component {
    render() {
        return (
            <div>
                ERROR PAGE
            </div>
        )
    }
}
 