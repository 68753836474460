import { Redirect, Route } from "react-router-dom";
import Sidebar from "../components/Sidebar";
function DashboardRoute ({components:Component, authed, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => authed === true ? (<><Sidebar  {...props} /><Component  {...props} /></>) 
        : (authed==false?<Redirect to={{pathname: '/login', state: {from: props.location}}} />:'')}
      />
    )
}
export default DashboardRoute;