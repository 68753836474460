import React from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

export default function Sidebar(props) {
/*   const checkActive = (match, location) => {
    if (!location) return false;
    const { pathname } = location;

    console.log(pathname === "/");
    return pathname === "/";
  }; */
  return (
    <>
      <div className="dashbord_left">
        <ProSidebar collapsed={false}>
          <Menu iconShape="round">
            <MenuItem>
              <NavLink to="/dashboard" activeClassName="isActive">
                <span className="MenuName">Dashboard</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/profile" activeClassName="isActive">
                <span className="MenuName">Profile</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink exact to="/orders/1" activeClassName="isActive">
                <span className="MenuName">Order</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/products/1" activeClassName="isActive">
                <span className="MenuName">Product Management</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/reports" activeClassName="isActive">
                <span className="MenuName">Reports</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/transaction-history/1" activeClassName="isActive">
                <span className="MenuName">Transaction History</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/coupons" activeClassName="isActive">
                <span className="MenuName">Coupons</span>
              </NavLink>
            </MenuItem>
            <SubMenu title="Account">
              <MenuItem>
                <NavLink to="/bank-statement" activeClassName="isActive">
                  <span className="MenuName">Bank Details</span>
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/store-timing" activeClassName="isActive">
                  <span className="MenuName">Store Timing</span>
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/address" activeClassName="isActive">
                  <span className="MenuName">Address</span>
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/change-password" activeClassName="isActive">
                  <span className="MenuName">Change Password</span>
                </NavLink>
              </MenuItem>
            </SubMenu>
            <MenuItem>
              <NavLink to="/help-active/1" activeClassName="isActive">
                <span className="MenuName">Help</span>
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                // to="/logout"
                onClick={() => {
                  reactLocalStorage.clear();
                  window.location.reload();
                  // reactLocalStorage.set("isLogin", false);
                }}
                to="/login"
                activeClassName="isActive"
              >
                <span className="MenuName">Sign Out</span>
              </NavLink>
            </MenuItem>
          </Menu>
        </ProSidebar>
        <div className="release">0.1.15</div>
      </div>
    </>
  );
}
