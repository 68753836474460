import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import apiVenodrProfile from "../services/api";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ".././Deep.css";
import apiVendorDashboard from "../services/api-vendorDashboard";
import TimeAgo from "react-timeago";

export default function Header(props) {
  const [isLogin, setIsLogin] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const [notification, setNotification] = useState([]);
  const [profile, setProfile] = useState({});

  // let location=useLocation()
  function setLoginHeader() {
    setIsLogin(props.isLogin);
  }
  useEffect(() => {
    setLoginHeader();

    if (props.isLogin) {
      apiVenodrProfile.getProfileData().then((res) => {
        let data = res.data.data;
        setProfile(data);
      });
    }
  }, [props.isLogin]);

  useEffect(() => {
    Notification();
  }, [props.isLogin]);

  function Notification() {
    setLoginHeader();
    if (props.isLogin) {
      let notiData = {
        page: 1,
        perPage: 20,
      };
      apiVendorDashboard
        .getNotificationPagin(notiData)
        .then((res) => {
          let data = res.data.result;
          setNotification(data);
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject(err);
        });
    }
  }

  function isRead() {
    // apiVendorDashboard
    // .getNotification()
    // .then((res) => {
    //   let data = res.data.data;
    //   data.forEach(obj=>{
    //     // console.log(obj.isRead)
    //     if(!obj.isRead){
    //       // console.log(obj.isRead)
    //       // return <i className="fa-regular fa-bell"></i>
    //       return false
    //     }
    //   //   if(true){
    //   //     return <i className="far fa-bell"></i>
    //   // }
    //   })
    //   // setNotification(data);
    //   console.log(data);
    // })
    // .catch((err) => {
    //   console.log(err);
    //   return Promise.reject(err);
    // });
  }
  function logOut() {
    props.isLogout();
  }
  const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

  return (
    <header className="header sticky-header">
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-md-between py-3">
          <div className="logo d-flex">
            <Link to="/">
              <span className="d-flex align-items-center">
                <img src="/assets/images/logo.svg" alt="" />
              </span>
            </Link>
          </div>
          <div className="heaDerAdress">
            <ul>
              {props.isLogin ? (
                <>
                  <li>
                    <Link to="/address">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {profile.address}
                    </Link>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {!props.isLogin && (
            <nav className="d-flex flex-wrap">
              <ul className="d-flex text-end ">
                <li className={`${isLogin && "active"}`}>
                  <Link to="/">Home</Link>
                </li>
                <li className={`${!isLogin && "active"}`}>
                  <Link to="/login">
                    <span> Sign in </span>
                  </Link>
                </li>
              </ul>
            </nav>
          )}
          {props.isLogin && (
            <div className="after_login">
              <ul className="d-flex text-end ">
                <li className="notice notification_popup_outer">
                  {/* <Link to="/notification"><span> <i className="far fa-bell"></i> </span></Link> */}
                  <Dropdown show={dropdownIsOpen} onToggle={toggleDropdown}>
                    <Dropdown.Toggle variant="thm" id="dropdown-basic">
                      {/* <i className={"far fa-bell"}></i> */}
                      {/* <i className={isRead()}></i> */}
                      {isRead() ? (
                        <i className="far fa-bell bell"></i>
                      ) : (
                        <i className="far fa-bell"></i>
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="notification_popup_inner">
                        <div className="notifyInside scroller">
                          {notification.map((data, key) => (
                            <div className="notifyWrappr  p-3" key={key}>
                              <div className="d-flex flex-wrap justify-content-between notifyHead">
                                <div className="notifyHeadinNamge">
                                  <h4>{data.title}</h4>
                                </div>
                                <div className="notifyTimer">
                                  <TimeAgo date={data.createdAt} />
                                </div>
                              </div>
                              <div className="notifyDescrp">
                                <p>{data.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Link to="/notification">
                          <span
                            onClick={() => toggleDropdown()}
                            className="btn cus_btn custom01 d-block"
                          >
                            {" "}
                            View All{" "}
                          </span>
                        </Link>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="admin_login">
                  <span>
                    <Dropdown>
                      <Dropdown.Toggle variant="thm" id="dropdown-basic">
                        {profile.image?.path ? (
                          <img
                            src={profile.image.path}
                            className="headerImage"
                            alt="profile"
                          />
                        ) : (
                          <i className={"fa fa-user"}></i>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              logOut();
                            }}
                          >
                       Sign Out
                          </button>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </li>

                {profile.storeName ? (
                  <span>{profile.storeName} </span>
                ) : (
                  <span>Loading </span>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}
