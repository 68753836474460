import React from "react";
// import { useLocation } from "react-router-dom";
// import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "react-pro-sidebar/dist/css/styles.css";
import "./assets/css/all.css";
import 'aos/dist/aos.css';
import "./sass/comman.css";
import "./assets/css/custom.css";
import "./assets/css/dev.css";

import Layout from "./pages/layout";
import AOS from 'aos';

function App() {
  AOS.init()
/*   let history = useHistory();
  const [isAuth, authDone] = useState(null);
  useEffect(() => {
    var tokenAccess = reactLocalStorage.get("token");
    var authType = tokenAccess !== undefined ? true : false;
    authDone(authType);
  }, []);
  useEffect(() => {
      if(isAuth && history){
        history.push('/dashboard');
      }
  }, [isAuth]);
  const logOut = () => {
    reactLocalStorage.clear();
    authDone(false);
  };
  const Login = () => {
    authDone(true);
  }; */
  // let history=useLocation()
  // console.log(history)
  return (
    <Layout  />
  );
}

export default App;
