import React, { Component, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route,useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import ErrorPage from "./ErrorPage";
import PrivateRoute from "../services/private-routes";
import DashboardRoute from "../services/dashboard.routes";
import { reactLocalStorage } from "reactjs-localstorage";
import apiVendorDashboard from "../services/api-vendorDashboard"
const HomePage = lazy(() => import("./home"));
// login
const LoginPage = lazy(() => import("./login"));
const VerifyEmail = lazy(() => import("./verifyEmail"));
const ForgotPassword = lazy(() => import("../pages/login/forgotPassword"));
const ChangePassword = lazy(() => import("../pages/login/changePassowrd"));
const OtpPage = lazy(() => import("../pages/login/otp"));
const PrivacyPage = lazy(() => import("./privacy"));
const TermsPage = lazy(() => import("./terms"));
const CancellationPage = lazy(() => import("./cancellation-policy"));
const AboutPage = lazy(() => import("./about"));
const FaqsPage = lazy(() => import("./faqs"));
const DashboardPage = lazy(() => import("./dashboard/dashboard"));
const ProfilePage = lazy(() => import("./profile/profile"));
const OrdersPage = lazy(() => import("./order/orders"));
const OrderDetailPage = lazy(() => import("./order/order-detail"));
const OrderViewPage = lazy(() => import("./order/order-view"));
const ProductManagementPage = lazy(() =>
  import("./product/product-management")
);
const ProductStockPage = lazy(() => import("./product/product-stock-update"));
const ProductViewPage = lazy(() => import("./product/product-view"));
const AddViewProductPage = lazy(() => import("./product/add-view-product"));
const ReportsPage = lazy(() => import("./report/reports"));
const HelpPage = lazy(() => import("./help/helpActive"));
const HelpPageClosed = lazy(() => import("./help/helpClosedTab"));
const RaiseTicketPage = lazy(() => import("./help/raise-ticket"));
const TicketPage = lazy(() => import("./help/ticket"));
const AddCouponPage = lazy(() => import("./coupons/add-coupon"));
const CouponListPage = lazy(() => import("./coupons/couponlist"));
const TransactionHistoryPage = lazy(() =>
  import("./history/transaction-history")
);
const BankStatementPage = lazy(() => import("./settings/bank-statement"));
const StoreTimingPage = lazy(() => import("./settings/store-timing"));
const AddressPage = lazy(() => import("./settings/addresses"));
const ChangePasswordPage = lazy(() => import("./settings/change-password"));
const NotificationsPage = lazy(() => import("./notification/notification"));

export default function Layout(props) {
  let location=useLocation() 

  const [isLogin, setIsLogin] = useState();
 
  function setLogin() {
    var tokenAccess = reactLocalStorage.get("token");
    var authType = tokenAccess !== undefined ? true : false;
    setIsLogin(authType);
  }
  function isLogout() {
    reactLocalStorage.clear();
    setIsLogin(false);
  }
  useEffect(() => {
    setLogin();
    if(location.pathname && isLogin){
    apiVendorDashboard
        .getNotification()
        .then((res) => {
          let data = res.data.data;
          // setNotification(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject(err);
        });
    
  }
  }, [isLogin,location.pathname]);
 
 

  console.log(location.pathname)
  
  
  return (
    
      <div className="dabMianwrp">
        <Header isLogin={isLogin} isLogout={() => isLogout()} />
        <div className="dashbord_outer">
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route exact path="/">
                <LoginPage loginSet={() => setLogin()} />
              </Route>
              <Route exact path="/home" component={HomePage} />
              <Route exact path="/verifyEmail" component={VerifyEmail} />
              <Route exact path="/login">
                <LoginPage loginSet={() => setLogin()} />
              </Route>
              <Route exact path="/privacy" component={PrivacyPage} />
              <Route exact path="/terms" component={TermsPage} />
              <Route
                exact
                path="/cancellation-policy"
                component={CancellationPage}
              />
              <Route exact path="/about" component={AboutPage} />
              <Route exact path="/faqs" component={FaqsPage} />

              <PrivateRoute
                exact
                authed={isLogin}
                path="/notification"
                components={NotificationsPage}
              />

              <DashboardRoute
                exact
                authed={isLogin}
                path="/dashboard"
                components={DashboardPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/profile"
                components={ProfilePage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/orders/:page"
                components={OrdersPage}
              />

              <DashboardRoute
                exact
                authed={isLogin}
                path="/orders/details/:orderId?"
                components={OrderDetailPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/orders/view/:orderId?"
                components={OrderViewPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/products/:page"
                components={ProductManagementPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/product/update/:prodId?"
                components={ProductStockPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/product/:prodId?/"
                components={ProductViewPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/add-product"
                components={AddViewProductPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/update-product/:prodId?"
                components={AddViewProductPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/reports"
                components={ReportsPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/help-active/:page"
                components={HelpPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/help-closed/:page"
                components={HelpPageClosed}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/raise-ticket"
                components={RaiseTicketPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/ticket/:ticketId"
                components={TicketPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/add-coupon"
                components={AddCouponPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/edit-coupon/:couponId"
                components={AddCouponPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/coupons"
                components={CouponListPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/transaction-history/:page"
                components={TransactionHistoryPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/bank-statement"
                components={BankStatementPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/store-timing"
                components={StoreTimingPage}
              />
              <DashboardRoute
                exact
                authed={isLogin}
                path="/address"
                components={AddressPage}
              />
              {/* loginRoutes */}
              <DashboardRoute
                exact
                authed={isLogin}
                path="/change-password"
                components={ChangePasswordPage}
              />
              <DashboardRoute
                authed={isLogin}
                path="/forgot_password"
                component={ForgotPassword}
              />
              <DashboardRoute
                authed={isLogin}
                path="/otp-page"
                component={OtpPage}
              />
              {/* <DashboardRoute authed={isLogin} path="/change_password"> */}
              <DashboardRoute authed={isLogin} path="/reset-password">
                <ChangePassword />
              </DashboardRoute>
              {/* loginRoutes End */}
              <Route path="*" exact={true} component={ErrorPage} />
            </Switch>
          </Suspense>
        </div>

        <Footer />
      </div>
    
  );
}
