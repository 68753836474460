import React from "react";
import { Link } from "react-router-dom";

export default function Header(props) {
  return (
    <footer className="footer_outer bg-light01  pb-1">
      <div className="container">
        <div className="footer_widget_outer d-flex justify-content-between py-sm-5">
          <div className="footer_widget f_logo">
            <Link to="/">
              <img src="./assets/images/Logo.png" alt="" />
            </Link>
            <ul>
                <li><a href="#"><i className="fas fa-map-marker-alt"></i> Jane Doe 123 Main Street  Dorm New York, NY 11377 USA</a></li>
                <li><a href="#"> <i className="fas fa-envelope"></i> info@dropoff.com </a></li>
                <li><a href="#"> <i className="fas fa-phone-alt"></i> +19999999999 </a></li>
            </ul>
          </div>
          <div className="footer_widget">
            <h5>Quick Links</h5>
            <ul>
              <li>
                <Link to="/about">
                  <span>About</span>
                </Link>
              </li>
              {/* <li><Link to="/faqs"> <span>FAQ's</span> </Link></li> */}
              <li>
                <span>
                  <Link
                    to="http://staging.alphonic.net.in:6300/faqs"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    FAQ's
                  </Link>
                </span>
              </li>
            </ul>
          </div>
          <div className="footer_widget">
            <h5>Legal Stuff</h5>
            <ul>
              <li>
                <Link to="/privacy">
                  {" "}
                  <span>Privacy Policy</span>{" "}
                </Link>
              </li>
              <li>
                <Link to="/terms">
                  {" "}
                  <span>Terms & Conditions</span>{" "}
                </Link>
              </li>
              <li>
                <Link to="/cancellation-policy">
                  {" "}
                  <span>Cancellation Policy</span>{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer_widget">
            <h5>follow on</h5>
            <ul className=" social_link d-flex">
              <li>
                <span>
                  {" "}
                  <i className="fab fa-facebook-f"></i>{" "}
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  <i className="fab fa-instagram"></i>{" "}
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  <i className="fab fa-youtube"></i>{" "}
                </span>
              </li>
              <li>
                <span>
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="copy_right text-center">
          <p>©Da DropOff. All rights reserved.</p>
        </div>
      </div>

      {/* <script src="https://code.jquery.com/jquery-3.6.0.js" ></script>
    <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/aos.js"></script>
    <script src="assets/js/custom.js"></script> */}
    </footer>
  );
}
